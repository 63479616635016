import request from '@/utils/request'

export default {
    pmList(params) {
        return request({
            url: '/v1/pmList',
            method: 'get',
            params
        })
    },
    getOne(id) {
        return request({
            url: `/v1/pm/${id}`,
            method: 'get'
        })
    },
    update(data) {
        return request({
            url: '/v1/pmUpdate',
            method: 'post',
            data
        })
    },
    create(data) {
        return request({
            url: '/v1/pmAdd',
            method: 'post',
            data
        })
    },
    archives(id) {
        return request({
            url: `/v1/pmArchive/${id}`,
            method: 'get'
        })
    },
    delete(id) {
        return request({
            url: `/v1/pmDel/${id}`,
            method: 'get'
        })
    },
    review(data) {
        return request({
            url: '/v1/pmReview',
            method: 'post',
            data
        })
    },
    appendParts(data) {
        return request({
            url: '/v1/woAppendParts',
            method: 'post',
            data
        })
    },
    removeParts(data) {
        return request({
            url: '/v1/woRemoveParts',
            method: 'post',
            data
        })
    },
    editParts(data) {
        return request({
            url: '/v1/pmEditParts',
            method: 'post',
            data
        })
    },
    needParts(data) {
        return request({
            url: '/v1/woNeedParts',
            method: 'post',
            data
        })
    },
    appendMember(data) {
        return request({
            url: '/v1/woAppendMember',
            method: 'post',
            data
        })
    },
    removeMember(data) {
        return request({
            url: '/v1/woRemoveMember',
            method: 'post',
            data
        })
    },
    appendTask(data) {
        return request({
            url: '/v1/pmAppendTask',
            method: 'post',
            data
        })
    },
    removeTask(data) {
        return request({
            url: '/v1/pmRemoveTask',
            method: 'post',
            data
        })
    },
    appendTaskGroup(data) {
        return request({
            url: '/v1/pmAppendTaskGroup',
            method: 'post',
            data
        })
    },
    appendTime(data) {
        return request({
            url: '/v1/woAppendTime',
            method: 'post',
            data
        })
    },
    removeTime(data) {
        return request({
            url: '/v1/woRemoveTime',
            method: 'post',
            data
        })
    },
    handOut(data) {
        return request({
            url: `/v1/pmHandOut`,
            method: 'post',
            data
        })
    },
    submitReading(data) {
        return request({
            url: '/v1/pmSubmitReading',
            method: 'post',
            data
        })
    },
    SelectEqTime(data) {
        return request({
            url: '/v1/selectEqTime',
            method: 'post',
            data
        })
    },
    monitor(data) {
        return request({
            url: '/v1/pmMonitorConfirm',
            method: 'post',
            data
        })
    },
    engineer(data) {
        return request({
            url: '/v1/pmEngineerConfirm',
            method: 'post',
            data
        })
    },
    photo(data) {
        return request({
            url: '/v1/photo64',
            method: 'post',
            data
        })
    }
}
